<!--  -->
<template>
  <div class="main has-header has-footer bg-gray">
    <mt-header fixed title="实名认证">
      <router-link to slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
    </mt-header>

    <div class="tips">大陆公民持有的本人有效二代身份证</div>

    <div class="upForm">
      <div class="pic-frame">
        <div class="mid-frame">
          <upload-idcard imgType="imgFront" @upBace="updateStatus"></upload-idcard>
        </div>
        <i class="border-red icon-corner left-top"></i>
        <i class="border-red icon-corner left-bottom"></i>
        <i class="border-red icon-corner right-top"></i>
        <i class="border-red icon-corner right-bottom"></i>
      </div>
    </div>

    <div class="upForm">
      <div class="pic-frame">
        <div class="mid-frame">
          <upload-idcard imgType="imgBack" @upBace="updateStatus"></upload-idcard>
        </div>
        <i class="border-red icon-corner left-top"></i>
        <i class="border-red icon-corner left-bottom"></i>
        <i class="border-red icon-corner right-top"></i>
        <i class="border-red icon-corner right-bottom"></i>
      </div>
    </div>

    <div class="footer-bt">
      <mt-button type="danger" size="large" @click="handleNext">下一步</mt-button>
    </div>
  </div>
</template>

<script>
import uploadIdcard from "@components/user/upload-idcard";
import { MessageBox } from "mint-ui";
export default {
  components: {
    uploadIdcard,
  },
  data() {
    return {
      user: {
        state: 0,
        userName: "",
        idNum: "",
      },
      imgFront: {
        msg: "请上传身份证人像页",
        status: false,
      },
      imgBack: {
        msg: "请上传身份证国徽页",
        status: false,
      },
    };
  },
  computed: {},
  watch: {},
  beforeDestroy() {
    const messageBox = document.getElementsByClassName(
      "mint-msgbox-btn mint-msgbox-cancel "
    );

    if (messageBox && messageBox[0]) {
      messageBox[0].click();
    }
  },
  methods: {
    updateStatus(obj) {
      this[obj.imgType].msg = obj.msg;
      this[obj.imgType].status = obj.status;
      if (obj.userState > 1) {
        this.user.state = obj.userState;
      }
      if (obj.userName) {
        this.user.userName = obj.userName;
      }
      if (obj.idNum) {
        this.user.idNum = obj.idNum;
      }
    },
    getUserInfo() {
      let data = {
        userNo: this.$store.getters.getUser.userId,
      };
      getUserInfo({}).then((res) => {
        this.user = res.data;
      });
    },
    handleNext() {
      if (this.user.state === 2) {
        this.$router.push("/user/authentication-check");
      } else if (!this.imgFront.status) {
        MessageBox(this.imgFront.msg);
      } else if (!this.imgBack.status) {
        MessageBox(this.imgBack.msg);
      } 
    },
  },
};
</script>

<style lang='less' scoped>
.contack-wrap {
  width: 100%;
  height: 100%;
  background-color: #fff;
  top: 50%;
  right: 0px;
  bottom: auto;
  left: auto;
  transform: translate3d(0px, -50%, 0px);
  transition: all 1s ease-out 1s;
}
.mint-popup {
  transition: 0.5s ease-out;
}
.tips {
  color: #ff2500;
  background-color: #fff2ef;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 0.9rem;
  text-align: left;
  padding-left: 20px;
}

.upForm {
  margin: 32px;
}

div.pic-frame {
  height: 0;
  position: relative;
  padding-bottom: 59.5%;
  input.file {
    display: none;
  }
  .mid-frame {
    position: absolute;
    top: 14px;
    left: 14px;
    right: 14px;
    bottom: 14px;
    .img-preview {
      border-radius: 12px;
      width: 100%;
      height: 100%;
    }
  }
  .icon-corner {
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
    border-style: solid;
    &.left-top {
      left: 0;
      top: 0;
      border-width: 4px 0 0 4px;
    }
    &.left-bottom {
      left: 0;
      bottom: 0;
      border-width: 0 0 4px 4px;
    }
    &.right-top {
      right: 0;
      top: 0;
      border-width: 4px 4px 0 0;
    }
    &.right-bottom {
      right: 0;
      bottom: 0;
      border-width: 0px 4px 4px 0;
    }
  }
}
</style>